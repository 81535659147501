<template>
  <div>
    <sign
      @completed="closeWindow"
      @exception="handleException"
      v-if="uploads.length && !completed"
      :uploads="uploads"
      :signer_id="signer_id"
      :property_id="property_id"
      :close="false"
      :remote="remote"
      :unit_id="unit_id"
      :appId="appId"
      :context="context"
    ></sign>

    <v-dialog
      v-model="completed"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">Document Signed!</v-card-title>
        <v-card-text>
          Your document has been signed successfully.  You can now close this window
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="error_state"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">An error Occurred!</v-card-title>

        <v-card-text>
          {{ errorGet('SignDocumentWidget') }}
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<script type="text/babel">
    import api from '../../assets/api.js';
    import Loader from '../assets/CircleSpinner.vue';
    import Status from '../includes/Messages.vue';
    import Sign from '../includes/SignDocuments.vue';
    import moment from 'moment';

    export default {
        name: "SignDocument",
        data() {
            return {
                signer_id: null,
                uploads: [],
                property_id: '',
                completed: false,
                unit_id: null,
                remote: false,
                appId: "",
                context:{}
            }
        },
        components:{
            Sign,
            Loader,
            Status
        },
        async created (){
           await this.fetchSession();
        },
        destroyed(){
        },
        filters:{

        },
        computed:{
          error_state(){
              return this.errorHas('SignDocumentWidget')
          }
        },
        mounted(){

        },
        methods: {
            handleException(e){
                console.log(JSON.stringify(e, null, 2));
            },
            closeWindow(){
                this.sessions = [];
                this.completed = true;
            },

            async fetchSession(){
                let response = await api.get(this, api.UPLOADS + 'sign-document/' +  this.$route.params.hash);

                if(response){
                    this.uploads = response.uploads;
                    this.property_id = response.property_id;
                    this.appId = response.appId || "";
                    this.context = response.context || {};
                }

                if (response && 'remote' in response) {
                  this.remote = response.remote;
                  this.unit_id = response.unit_id || null;
                }

            }
        }
    }
</script>

<style scoped>
  .img-view{
    position: relative;
    flex: 0 0 150px;

  }
  .img-view img{
    max-width: 40px;
    width: 100%;
  }
  .pdf-holder{
    padding: 0px;

  }


  .sign-modal-head{
    z-index: 10;
    position: relative;
  }
  .sign-modal{
    margin: 10px;
  }
</style>
